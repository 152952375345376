import React, { useState, useEffect, useRef } from "react";
import StripeCheckout from "react-stripe-checkout";
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const Payments = () => {
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [donationAmount, setDonationAmount] = useState(0);

  const apikey = process.env.REACT_APP_STRIPE_PAYEMENTS_PUBLISHABLE_KEY;
  const history = useHistory();
  const location = useLocation();
  const paymentButtonRef = useRef(null); // Ref for the payment button

  useEffect(() => {
    // Focus on the payment button when component mounts
    if (paymentButtonRef.current) {
      paymentButtonRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    // Disable scrolling until payment is successful
    if (!isPaymentSuccessful) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean-up function to re-enable scrolling after unmount or successful payment
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isPaymentSuccessful]);

  const handleToken = (token) => {
    // Your server-side logic was here previously
    // This is a dummy client-side implementation
    setIsPaymentSuccessful(true); // Mark payment as successful

    // Fetch URL and extract uid parameter
    const searchParams = new URLSearchParams(location.search);
    const uid = searchParams.get('uid');

    // Redirect to the same URL with uid parameter
    if (uid) {
      history.push(`${location.pathname}?uid=${uid}`);
    } else {
      history.push(location.pathname);
    }
  };

  const handleDonationChange = (e) => {
    setDonationAmount(e.target.value);
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      {!isPaymentSuccessful && (
        <div className="bg-white p-8 rounded-lg shadow-lg" ref={paymentButtonRef}>
          <div className="mb-4">
            <StripeCheckout
              stripeKey={apikey}
              token={handleToken}
              billingAddress
              shippingAddress
              name="Alzo Health"
              amount={100} // Set the fixed amount for the Pay $1 button
            >
              Pay $1
            </StripeCheckout>
          </div>
          <div>or</div>
          <div className="mb-4">
            <input
              placeholder="Enter the donation amount in USD"
              value={donationAmount}
              onChange={handleDonationChange}
              className="border border-gray-300 rounded px-4 py-2"
            />
          </div>
          <div>
            <StripeCheckout
              stripeKey={apikey}
              token={handleToken}
              billingAddress
              shippingAddress
              name="Alzo Health"
              amount={donationAmount * 100} // Convert donation amount to cents
            >
              Pay Donation
            </StripeCheckout>
          </div>
        </div>
      )}
      {isPaymentSuccessful && (
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <p className="text-green-600 font-semibold">Your payment has been processed successfully</p>
        </div>
      )}
    </div>
  );
};

export default Payments;
