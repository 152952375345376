import React, { useState, useEffect } from "react";
import Axios from "axios";
import Loader from "./Loader";

import {useDispatch, useSelector} from "react-redux";
import { updateScoreOfUser } from "../store/scoreSlice";
import { type } from "@testing-library/user-event/dist/type";

const ColorBar = () => {
  const [score, setScore] = useState(0);
  const [text, setText] = useState("");
  const [userScore, setUserScore] = useState({});
  const [uid, setUid] = useState("");
  const [total_score, setTotalScore] = useState(0);
  const [max_score, setMaxScore] = useState(137)
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const current_score = useSelector((state) => state.score.userScore);

  useEffect(() => {
    async function fetchData() {
      try {
        // Getting uid from url
        const urlParams = new URLSearchParams(window.location.search);
        const user_id = (urlParams.get("uid"));
        setUid(user_id);
        console.log("From url: ",user_id);
        console.log("From url type: ", typeof(user_id));
        const uid_to_pass = {
          uid: user_id
        };
        console.log(uid_to_pass)
        // Minicog response
        const minicogResponse = await Axios.post(
          "https://us-central1-striped-rhino-401016.cloudfunctions.net/fetch-minicog-final-1",
          uid_to_pass
        );
        // Questionnaire response
        const questionnaireResponse = await Axios.post(
          "https://us-central1-striped-rhino-401016.cloudfunctions.net/fetch-questionnaire-final-2",
          uid_to_pass
        );
        // Getting data of questionnaire for different years
        const  parsedQuestionnaireResponses = JSON.parse(questionnaireResponse.data["userResponses"]);
        const parsedMiniCogResponses = await minicogResponse.data["userResponses"];
        console.log("Minicog response: ",parsedMiniCogResponses);
        console.log("Questionnaire response: ", parsedQuestionnaireResponses);
console.log("minicog",minicogResponse);
        setUserScore(prevUserScore => ({
          ...prevUserScore,
          questionnaire: parsedQuestionnaireResponses,
          minicog: parsedMiniCogResponses
        }));
        
        // Calculating total score
        const total =
          parseInt(minicogResponse.data["userResponses"]["total"]) +
          parseInt(JSON.parse(questionnaireResponse.data["userResponses"])["score"]);
          setTotalScore(total);
          updateSpectra(total);

         // Dispatch the action to update the score once all data is available
         dispatch(updateScoreOfUser({ userData: { questionnaire: parsedQuestionnaireResponses, minicog: parsedMiniCogResponses } }));
        
          setLoading(false);
      } catch (error) {
        console.error("Error while making the request:", error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Updated User Score:", userScore); // Logging updated userScore
    console.log("From store: ", current_score)
  }, [userScore]); // Run this useEffect whenever userScore changes

  const updateSpectra = (total) => {
    const reduced_score = Math.round((total/max_score) * 100);
    // You can calculate and update spectra score here based on the 'total' value
    if (reduced_score < 5) {
      setText("Mild to no cognitive impairment/Early Stage");
    } else if (reduced_score < 10) {
      setText("Mild cognitive impairment/Intermediate Stage");
    } else if (reduced_score < 14) {
      setText("Moderate cognitive impairment/Advanced Stage");
    } else {
      setText("Severe cognitive impairment/Severe Stage");
    }
    // Update 'score' based on 'total'
    setScore(reduced_score);
  };

  return (
    <div>
      {
        loading ? (
          <Loader/>
        ) : (
          <div>
             <div className="bg-mainViolet px-12 py-8 mb-8 lg:px-32 md:px-24">
            <p className="font-bold text-white text-4xl ">Test result overview:-</p>
            <div className="mt-8">
            <p className="font-semibold text-white text-2xl mb-4">Score obtained:- {total_score}</p>
            {/* <p className="font-semibold text-white text-2xl mb-4">Max score:- {max_score}</p> */}
            <p className="font-semibold text-white text-2xl mb-4">Result:- {text}</p>
          </div>
          <div>
          
        </div>
        
      </div>
      <p className="font-bold text-4xl px-12 lg:px-32 md:px-24 text-buttonViolet mb-8 ">Your result spectra:-</p>
      <div className="h-20 bg-gradient-to-r from-green-500 via-yellow-500 to-red-500 rounded-lg mx-auto my-8 w-4/5 relative px-4">
      <span
        style={{
          position: "absolute",
          top: "-25px",
          left: `calc(${score}% - 4%)`,
          whiteSpace: "nowrap",
        }}
        className="text-buttonViolet font-bold text-sm md:text-base"
      >
        You are here
      </span>
      <div
        className="h-full bg-blue-500 rounded-lg"
        style={{
          width: "2%",
          left: `${score}%`,
          transform: "translateX(-50%)",
          position: "absolute",
        }}
      ></div>
          </div>
          </div>
        )
      }
     
    </div>
  );
};

export default ColorBar;
