import React from 'react'
import Header from '../components/Header'
import ColorBar from '../components/ColorBar'
import Footer from '../components/Footer'
import Chatbot from '../components/chatbot'
import BarPlot from '../components/Graphs/BarPlot'
import RiskMatrix from '../components/Graphs/RiskMatrix'
import PaymentPopup from '../components/payments/Payment-link'
import Payments from '../components/payments/Payment'
export default function Result() {
  return (
    <div className='bg-lightViolet'>
          <Header/> 
          {/* <Payments />  */}

          <ColorBar />
          <PaymentPopup /> 
          <div className='flex justify-evenly '>
            <BarPlot/> 
            {/* <RiskMatrix/> */}
          </div> 
          <Chatbot />
          <Footer/>
    </div>
  )
}
