import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userScore: null
}


const scoreSlice = createSlice({
    name: "score",
    initialState,
    reducers: {
        updateScoreOfUser: (state, action) => {
            state.userScore = action.payload.userData;
        }
    }
})

export const {updateScoreOfUser} = scoreSlice.actions;

export default scoreSlice.reducer;