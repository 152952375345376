  import React, { useState, useEffect, useRef } from 'react';
  import axios from 'axios';
  import { loadStripe } from '@stripe/stripe-js';
  import StripeCheckout from 'react-stripe-checkout';
  import '../payments/payment.css'

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PAYMENTS_SECRET_KEY);

  const PaymentPopup = ({ onClose, onPaymentSuccess }) => {
    const [donationAmount, setDonationAmount] = useState('');
    const [showStripeCheckout, setShowStripeCheckout] = useState(false);
    const [donateClicked, setDonateClicked] = useState(false);
    const [email, setEmail] = useState('');
    const popupRef = useRef(null);

    // Function to generate JWT token and store in local storage
    const generateAndStoreUID = () => {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams(window.location.search);
        const uid_from_url = params.get("uid");
        if(uid_from_url){
          sessionStorage.setItem("UID", uid_from_url);
          console.log("set item") // Store UID in local storage
          console.log("UID from payment", uid_from_url); // Print UID in console
        }
        
        // const uid_from_sessionstorage = sessionStorage.getItem("UID");
        // console.log(uid_from_sessionstorage)  
        resolve(uid_from_url);
      });
    };
// generateAndStoreUID();
     const checkifUID = async () => {
       try {
         const uid = await generateAndStoreUID();
         console.log("UID set in sessionstorage:", uid);
         // Fetch the UID from localStorage and do something with it
         const params = new URLSearchParams(window.location.search);
         const uid_after_reload = params.get("uid");
         if(uid_after_reload==null){
           const fetched_uid = sessionStorage.getItem('UID');
           console.log("Fetched UID from session storage:", fetched_uid);
           if (fetched_uid) {
             // Check if the URL already contains a uid parameter
             if (!params.has('uid')) {
               params.append('uid', fetched_uid);
               const newURL = `${window.location.pathname}?${params.toString()}${window.location.hash}`;
               window.history.replaceState({}, '', newURL);
             }
           }
         }
    
         // Do something with the fetched UID
       } catch (error) {
         console.error("Error generating or fetching UID:", error);
       }
     };

  checkifUID();

    // Call generateAndStoreUID before the component renders

    useEffect(() => {
      const paymentToken = sessionStorage.getItem("PaymentToken")
      if(paymentToken==="done"){
        alert("You may now close the Payment Popup by clicking on the Close Button")
      }
//       const checkPaymentToken = () => {
//         const paymentToken = sessionStorage.getItem("PaymentToken");
//         if (paymentToken === "done") {
//           // If payment token is already done, close the payment popup
//           document.body.style.overflow = 'auto'
// // handleClose()      
//   } else {
//           // Otherwise, show the payment popup
//           setShowStripeCheckout(true);
//         }
//       };
//       checkPaymentToken();

  
      const params = new URLSearchParams(window.location.search);
      const emailParam = params.get('email');
      setEmail(emailParam);

      // Check if payment token is present in URL
      // const paymentToken = params.get('payment');
      // if (paymentToken) {
      //   setShowStripeCheckout(true);
      // } else {
      //   // Alert user to make a payment first
      //   alert('Please make a payment first.');
      // }
      document.body.style.overflow = 'hidden'; // Disable scrolling when popup is open

      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          handleClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        // document.body.style.overflow = 'auto'; // Re-enable scrolling when popup is closed
        document.removeEventListener('mousedown', handleClickOutside);

      };
    }, []);

    // const updateURLWithPaymentToken = () => {
    //   const params = new URLSearchParams(window.location.search);
    //   const paymentToken = params.get('payment');
    //   localStorage.setItem('payment', 'true');
    // };

    const handlePayOneDollar = async () => {
      try {
        const payment_url = process.env.REACT_APP_STRIPE_PAYMENTS_LINK;
        const session_id = "test_00geX24jC40S3x69AA";
        sessionStorage.setItem("PaymentToken", "done");

        // updateURLWithPaymentToken();
window.location.href = payment_url      } catch (error) {
        console.error('Error redirecting to Stripe checkout:', error);
      }
    };

    const handleDonate = async () => {
      // setDonateClicked(true);
      // setShowStripeCheckout(true);
      // updateURLWithPaymentToken();
      const payment_url = process.env.REACT_APP_STRIPE_DONATION_LINK;
      sessionStorage.setItem("PaymentToken", "done");
      window.location.href = payment_url 
      // alert("Coming Soon");
    };

    const handleToken = async (token) => {
      console.log('Stripe token:', token);
      try {
        await axios.post('/send-email', { email: email });
        console.log('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };

    const handleClose = () => {
      const params = new URLSearchParams(window.location.search);
      const paymentToken = sessionStorage.getItem('PaymentToken');
      console.log("vghbjnknhbg", paymentToken)

      if (paymentToken=="done") {
        const popup = document.getElementById('paymentPopup');
        const background = document.getElementById('modalBackground');
        if (popup && background) {
          popup.style.display = 'none';
          background.style.opacity = '0';
          document.body.style.overflow = 'auto';
          background.remove();
          popup.remove();
        }
      } else {
        alert('Please make a payment first.');
      }
    };

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleDonate();
      }
    };

    return (
      <div id='modalBackground' className="fixed inset-0 flex items-center justify-center z-50">
        <div className="modal-background fixed inset-0 bg-gray-500 opacity-90"></div>
        <div id='paymentPopup' className="modal-content bg-buttonViolet w-1/2 p-6 rounded-lg z-10 text-white">
          <h2 className="text-2xl font-semibold mb-4">Payment Options</h2>
          <hr />
          <p className='text-xl mt-4 font-light'>Welcome to our Result and Analysis Page! We want to make sure we provide you with the best analysis of your results. Please select a payment option to view your results:</p>

          <div className="flex mt-4 mb-4">
            <div className="flex flex-col items-center justify-center hover:bg-lightViolet hover:rounded p-8 hover:text-black cursor-pointer" onClick={handlePayOneDollar}>
              <div>
                <img src="images/one_dollar_payments.svg" alt="Image 1" className="w-45 h-50 rounded-full" />
              </div>
              <div className="mt-2">
                <p className="font-bold">Pay One Dollar</p>
              </div>
            </div>

            <div className="h-100 w-1 bg-gray-400 mx-4"></div>

            <div className="flex flex-col items-center justify-center hover:bg-lightViolet hover:rounded p-8 hover:text-black cursor-pointer" onClick={handleDonate}>
              <div>
                <img src="images/donation.svg" alt="Image 2" className="w-45 h-50 rounded-full" />
              </div>
              <div className="mt-2">
                <p className="font-bold">Donate</p>
              </div>
            </div>
            {donateClicked && (
              <div className="flex flex-row items-center justify-center mr-4"> Enter Donation Amount:
                <input
                  type="number"
                  value={donationAmount}
                  onChange={(e) => {
                    const value = Math.max(0, parseInt(e.target.value));
                    setDonationAmount(value);
                  }}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter donation amount"
                  className="bg-lightViolet text-black rounded-md py-2 px-4 ml-2 mr-2 w-36"
                />
              </div>
            )}
          </div>

          <div className="flex mt-4 mb-4">
            {showStripeCheckout && (
              <div>
                <StripeCheckout
                  stripeKey={process.env.REACT_APP_STRIPE_PAYMENTS_PUBLISHABLE_KEY}
                  token={handleToken}
                  billingAddress
                  shippingAddress
                  name="Alzo Health"
                  amount={donationAmount * 100}
                >
                  {donationAmount && (
                    <button className="bg-lightViolet text-black hover:bg-purple-700 hover:text-white rounded-lg py-2 px-4" onClick={handleDonate}>
                      Donate
                    </button>
                  )}

                  {!donationAmount && (
                    <button className="flex flex-col items-center bg-lightViolet text-black hover:bg-purple-700 hover:text-white rounded-lg py-2 px-4" onClick={handleDonate}>
                      Donate
                    </button>
                  )}
                </StripeCheckout>
              </div>
            )}
          </div>

          <button onClick={handleClose} className="mt-4 w-full bg-mainViolet text-white hover:bg-purple-700 hover:text-white rounded-lg py-2 px-4">
            Close
          </button>
        </div>
      </div>
    );
  };

  export default PaymentPopup;
