import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useSelector } from 'react-redux';
import axios from 'axios';

const BarPlot = () => {
  const scoreData = useSelector((state) => state.score.userScore);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [layout, setLayout] = useState(null);

  const config = {
    responsive: true,
  };

  useEffect(() => {
    const fetchData = async () => {
      if (scoreData) {
        let years;
        if (
          scoreData.questionnaire.scores2020 == 0 ||
          scoreData.questionnaire.scores2021 == 0 ||
          scoreData.questionnaire.scores2023 == 0 ||
          scoreData.questionnaire.scores2024 == 0
        ) {
          const response = await axios.get(
            'https://us-central1-striped-rhino-401016.cloudfunctions.net/Questionnaire_New-2'
          );
          years = response.data;
        } else {
          const response = await axios.get(
            'https://us-central1-striped-rhino-401016.cloudfunctions.net/Questionnaire_New-4'
          );
          years = response.data;
        }
console.log("years are : ",years);
        const updatedData = [
          {
            x: [years.year1, years.year2, years.year3, years.year4],
            y: [
              66-scoreData.questionnaire.scores2020,
              66-scoreData.questionnaire.scores2023,
              66-scoreData.questionnaire.scores2021,
              66-scoreData.questionnaire.scores2024,
            ],
            type: 'bar',
            marker: {
              color: '#E2DFF5',
              width: 0.5,
            },
          },
          
        ];

        const maxScore = Math.max(
          scoreData.questionnaire.scores2020,
          scoreData.questionnaire.scores2021,
          scoreData.questionnaire.scores2023,
          scoreData.questionnaire.scores2024
        );
        const newRange = [0, maxScore + 50];

        setData(updatedData);

        setLayout({
          title: {
            text: 'Bar Graph',
            x: 0.5,
            y: 0.95,
            xanchor: 'center',
            yanchor: 'top',
            font: {
              family: 'Arial',
              size: 18,
              color: '#333',
            },
          },
          plot_bgcolor: '#9690EA',
          paper_bgcolor: '#9690EA',
          margin: {
            b: 0,
            l: 90,
            r: 90,
            t: 50,
            pad: 1,
          },
          xaxis: {
            tickmode: 'array',
            tickvals: [years.year1, years.year2, years.year3, years.year4],
            title: 'Year',
            showline: true,
            automargin: true,
          },
          yaxis: {
            title: 'Score',
            showline: true,
            tickvals: [
              66-scoreData.questionnaire.scores2020,
              66-scoreData.questionnaire.scores2023,
              66-scoreData.questionnaire.scores2021,
              66-scoreData.questionnaire.scores2024,
            ],
            ticktext:[
              66-scoreData.questionnaire.scores2020,
              66-scoreData.questionnaire.scores2023,
              66-scoreData.questionnaire.scores2021,
              66-scoreData.questionnaire.scores2024,
            ],
            range: [null, maxScore + 50],
            automargin: false,
          },
        });

        setLoading(false);
      }
    };

    fetchData();
  }, [scoreData]);

  return (
    <div className="lg:w-1/3 md:w-1/2 w-5/6 rounded-2xl overflow-hidden shadow-lg mx-8 my-4">
  {loading ? (
    <p>Loading...</p>
  ) : (
    <div className="flex flex-row rounded-2xl p-0 m-0 overflow-hidden shadow-lg">
      <div className="w-3/4">
        {data && layout && (
          <Plot
            data={data}
            layout={layout}
            config={config}
            useResizeHandler={true}
            style={{ display: 'block', margin: '0', padding: '0' }}
          />
        )}
      </div>
      <div className="bg-gray-200 p-4 w-64">
  <p>Additional Information:</p>
  <ul className="list-disc pl-4">
    <li>The higher bar graph year indicates better cognitive capabilities.</li>
    <li>The more a person's score on the bar graph, the better they function in that year.</li>
  </ul>
</div>

    </div>
  )}
</div>

   
  );
 
};

export default BarPlot;