import React, { useState, useEffect } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import Axios from "axios";
import config from './config.json';
import {useDispatch, useSelector} from "react-redux";
import { updateScoreOfUser } from "../store/scoreSlice";


// Access the API key
const API_KEY = config.API_KEY;

const systemMessage = {
  "role": "system",
  "content": "Explain things like you're talking to a person with questions regarding the cognitive world and only respond to cognitive related questions or else ignore the rest and do not respond to questions like what is fire. Always note that the more the spectra score the more cognition problem(it ranges from 0 to 15) and always maintain my score"
}

function Chatbot() {
  const [uid, setUid] = useState("");
  const [minicog_total, setMinicogTotal] = useState(0);
  const [questionnaire_total, setQuestionnaireTotal] = useState(0);
  const [total_score, setTotalScore] = useState(0);
  const [minicog_data, setMinicogData] = useState({});
  const [questionnaire_data, setQuestionnaireData] = useState({});
 
  const [messages, setMessages] = useState([
    // Include the system message initially


    {
      message: "Hello, I'm AI Assist! Ask me anything related to the cognitive world!",
      sentTime: "just now",
      sender: "ChatGPT",
    }
  ]);
  useEffect(() => {
    // Function to get the current time in IST
    const getCurrentIST = () => {
      const currentDate = new Date();
      const currentIST = new Date(currentDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
      return currentIST.toLocaleTimeString('en-US', { hour12: true, timeZoneName: 'short' });
    };

    // Update the sentTime of the first message with the current time in IST
    const updatedMessages = [...messages];
    updatedMessages[0].sentTime = getCurrentIST();
    setMessages(updatedMessages);
  }, [])
  const [isTyping, setIsTyping] = useState(false);
  const [isInitialPromptSent, setIsInitialPromptSent] = useState(false);
  const dispatch = useDispatch();
  const current_score = useSelector((state) => state.score.userScore);
  useEffect(() => {
    async function fetchData() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const user_id = urlParams.get("uid");
        setUid(user_id);
        const uid_to_pass = {
          uid: user_id,
        };

        const minicogResponse = await Axios.post(
          "https://us-central1-striped-rhino-401016.cloudfunctions.net/fetch-minicog-final-1",
          uid_to_pass
        );
        setMinicogData(minicogResponse.data);
        setMinicogTotal(minicogResponse.data["userResponses"]["total"]);
        const questionnaireResponse = await Axios.post(
          "https://us-central1-striped-rhino-401016.cloudfunctions.net/fetch-questionnaire-final-2",
          uid_to_pass
        );
        setQuestionnaireData(questionnaireResponse.data);
        setQuestionnaireTotal(
          JSON.parse(questionnaireResponse.data["userResponses"])["score"]
        );

        const calculatedTotal =
          minicogResponse.data["userResponses"]["total"] +
          Math.round(
            parseInt(
              JSON.parse(questionnaireResponse.data["userResponses"])["score"]
            )
          );
        setTotalScore(calculatedTotal);

        // Display the initial prompt with the total score
        const initialPrompt = `My score/spectra score is ${calculatedTotal}. 0-4 Points: Mild to no cognitive impairment/Early Stage 5-9 Points: Mild cognitive impairment/Intermediate Stage 10-13 Points: Moderate cognitive impairment/Advanced Stage 13-15 Points: Severe cognitive impairment/Severe Stage. Give a short summary on my score and remember it if I ask. Do not show me the range, just give me a large summary. Show me my score/spectra score as well.`;
      
        sendPromptToGPT(initialPrompt);
        setIsInitialPromptSent(true);
      } catch (error) {
        console.error("Error while making the request:", error);
      }
    }
    fetchData();

  }, []);

  const sendPromptToGPT = async (prompt) => {
    setIsTyping(true);

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        systemMessage,
        { role: "user", content: prompt } // Include summary with the current prompt
      ]
    };

    await fetch("https://api.openai.com/v1/chat/completions",
      {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
      }).then((data) => {
        return data.json();
      }).then((data) => {
        // Display the response from the initial prompt
        setMessages([
          ...messages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT"
          }
        ]);
        setIsTyping(false);
      });
  };

  const handleSend = async (message) => {
    // Handle user's subsequent messages here, if needed
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user"
    };
    /*const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        systemMessage,
        { role: "user", content:` summarize this array within 100 tokens ${messages}` } // Include summary with the current prompt
      ]
    };

    console.log(apiRequestBody);

    await fetch("https://api.openai.com/v1/chat/completions",
      {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
      }).then((data) => {
        return data.json();
      }).then((data) => {
        // Display the response from the initial prompt
        setMessages([
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT"
          }
        ]);
      });
*/
    const newMessages = [...messages, newMessage];
console.log("the messages are",messages);
    setMessages(newMessages);
    const requestData = {
      userResponses:messages,
      uid: uid,
    };
    await Axios.post('https://us-central1-striped-rhino-401016.cloudfunctions.net/chat-user-response', requestData);
    // For subsequent user messages, process them as usual
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    // Format messages for chatGPT API
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message }
    });

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        systemMessage,
        ...apiMessages
      ]
    };


    await fetch("https://api.openai.com/v1/chat/completions",
      {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
      }).then((data) => {
        return data.json();
      }).then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT"
          }
        ]);
        setIsTyping(false);
      });
  }
  console.log(messages);

  return (
    <div className='my-8'>
      <p className='font-bold text-4xl text-buttonViolet mb-8 px-12 lg:px-32 md:px-24'>Chat with your AI assistant to discuss your results:-</p>
      <div className="w-full mx-auto flex justify-center items-center flex-col">
        <div className='position-relative w-5/6 h-full' >
          <MainContainer className='p-4 rounded-xl'>
            <ChatContainer>
              <MessageList
                scrollBehavior="smooth"
                typingIndicator={isTyping ? <TypingIndicator content="AI Assist is typing" /> : null}
              >
                {messages.map((message, i) => {
                  console.log(message)
                  return <Message key={i} model={message} />
                })}
              </MessageList>
              <MessageInput placeholder="Type message here" onSend={handleSend} />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
    </div>

  );
}

export default Chatbot;